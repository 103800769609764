import { template as template_6fa1ae4562494fd6919a7805cba509c3 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_6fa1ae4562494fd6919a7805cba509c3(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
