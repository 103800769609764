import { template as template_11dbf0141b954b47a7db6fab5c8c2c9d } from "@ember/template-compiler";
const SidebarSectionMessage = template_11dbf0141b954b47a7db6fab5c8c2c9d(`
  <div class="sidebar-section-message-wrapper sidebar-row">
    <div class="sidebar-section-message">
      {{yield}}
    </div>
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default SidebarSectionMessage;
